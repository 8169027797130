var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "movement-documents"
    }
  }, [_c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('documents-header', {
    attrs: {
      "page_type": _vm.page_type
    },
    on: {
      "date_range": _vm.updateDateRange
    }
  }), _c('div', {
    staticClass: "b-table__content"
  }, [_c('documents-table-filter', {
    ref: "doc_table_filter",
    attrs: {
      "edit_fields": true,
      "table_name": "invoice-docs",
      "placeholder": "Поиск",
      "total": _vm.total_sum,
      "selected_sum": _vm.selected_sum
    },
    on: {
      "handler_pagination": _vm.handlerSearch,
      "clear": _vm.clearSearchStr
    }
  }), _c('resizable-table', {
    staticClass: "invoice-docs",
    staticStyle: {
      "margin-top": "0 !important"
    },
    attrs: {
      "inside_card": false,
      "table_name": "invoice-list",
      "empty_text": "Ничего не найдено",
      "items": _vm.items,
      "default_fields": _vm.fields,
      "busy": _vm.isBusy || _vm.$apollo.queries.InvoiceHeads.loading
    },
    on: {
      "row-click": _vm.rowClicked
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('b-checkbox', {
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var item = _ref.item,
            value = _ref.value;
        return [_c('div', {
          staticClass: "b-status",
          class: {
            success: item.fixed
          }
        }), _c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-checkbox', {
          attrs: {
            "value": value
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })], 1)];
      }
    }, {
      key: "body_date",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "b-date text-left"
        }, [_vm._v(_vm._s(item.number))]), _c('div', {
          staticClass: "b-date date mt-1 table-substr text-left"
        }, [_vm._v(" " + _vm._s(item.date ? _vm.formatDate(item.date) : '') + " ")])];
      }
    }, {
      key: "body_fixed",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('b-badge', {
          class: {
            success: item.fixed
          }
        }, [_vm._v(" " + _vm._s(item.fixed ? 'Проведен' : 'Черновик') + " ")])];
      }
    }, {
      key: "body_sum",
      fn: function (_ref4) {
        var value = _ref4.value;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ")])];
      }
    }, {
      key: "body_retail_sum",
      fn: function (_ref5) {
        var value = _ref5.value;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ")])];
      }
    }, {
      key: "body_sender_entity",
      fn: function (_ref6) {
        var value = _ref6.value;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(value.name) + " ")])];
      }
    }, {
      key: "body_payed",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('b-badge', {
          class: {
            success: item.payed
          }
        }, [_vm._v(" " + _vm._s(item.payed ? 'Оплачен' : 'Не оплачен') + " ")])];
      }
    }, {
      key: "body_recipient_entity",
      fn: function (_ref8) {
        var value = _ref8.value;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(value.name) + " ")])];
      }
    }, {
      key: "body_owner",
      fn: function (_ref9) {
        var _item$owner$last_name, _item$owner, _item$owner$first_nam, _item$owner2;

        var item = _ref9.item;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s((_item$owner$last_name = (_item$owner = item.owner) === null || _item$owner === void 0 ? void 0 : _item$owner.last_name) !== null && _item$owner$last_name !== void 0 ? _item$owner$last_name : '') + " " + _vm._s((_item$owner$first_nam = (_item$owner2 = item.owner) === null || _item$owner2 === void 0 ? void 0 : _item$owner2.first_name) !== null && _item$owner$first_nam !== void 0 ? _item$owner$first_nam : '') + " ")])];
      }
    }, {
      key: "body_payed_date",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.payed_date ? _vm.formatDate(item.payed_date) : '') + " ")];
      }
    }])
  }), _c('div', {
    staticClass: "movement-documents__count-element"
  }, [_c('p', {
    staticClass: "movement-documents__count-element-text"
  }, [_vm._v("Всего: " + _vm._s(_vm.total))])])], 1)], 1), _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "clear_selected": _vm.clearSelected,
      "all_items": _vm.items,
      "items_name": "invoice"
    },
    on: {
      "remove_items": _vm.removeHeads,
      "create_sale": _vm.createSale
    }
  }), _c('pagination', {
    attrs: {
      "total": _vm.total,
      "table_name": "invoice-docs"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }