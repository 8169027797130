<template>
  <section id="movement-documents">
    <!-- <div class="content__header">{{ title }}</div> -->
    <div class="b-table__wrapper">
      <documents-header
        :page_type="page_type"
        @date_range="updateDateRange"
      />
      <div class="b-table__content">
        <documents-table-filter
          ref="doc_table_filter"
          :edit_fields="true"
          table_name="invoice-docs"
          placeholder="Поиск"
          :total="total_sum"
          :selected_sum="selected_sum"
          @handler_pagination="handlerSearch"
          @clear="clearSearchStr"
        />
        <resizable-table
          style="margin-top: 0 !important"
          :inside_card="false"
          class="invoice-docs"
          table_name="invoice-list"
          empty_text="Ничего не найдено"
          :items="items"
          :default_fields="fields"
          :busy="isBusy || $apollo.queries.InvoiceHeads.loading"
          @row-click="rowClicked"
        >
          <template #head_id>
            <b-checkbox v-model="select_all" />
          </template>

          <template #body_id="{ item, value }">
            <div
              class="b-status"
              :class="{ success: item.fixed }"
            ></div>
            <div @click.stop>
              <b-checkbox
                v-model="selected"
                :value="value"
              />
            </div>
          </template>

          <template #body_date="{ item }">
            <div class="b-date text-left">{{ item.number }}</div>
            <div class="b-date date mt-1 table-substr text-left">
              {{ item.date ? formatDate(item.date) : '' }}
            </div>
          </template>

          <template #body_fixed="{ item }">
            <b-badge :class="{ success: item.fixed }">
              {{ item.fixed ? 'Проведен' : 'Черновик' }}
            </b-badge>
          </template>

          <template #body_sum="{ value }">
            <span class="d-flex float-right">
              {{ formatPrice(value) }}
            </span>
          </template>

          <template #body_retail_sum="{ value }">
            <span class="d-flex float-right">
              {{ formatPrice(value) }}
            </span>
          </template>

          <template #body_sender_entity="{ value }">
            <span class="d-flex float-left">
              {{ value.name }}
            </span>
          </template>

          <template #body_payed="{ item }">
            <b-badge :class="{ success: item.payed }">
              {{ item.payed ? 'Оплачен' : 'Не оплачен' }}
            </b-badge>
          </template>

          <template #body_recipient_entity="{ value }">
            <span class="d-flex float-left">
              {{ value.name }}
            </span>
          </template>

          <template #body_owner="{ item }">
            <span class="d-flex float-left">
              {{ item.owner?.last_name ?? '' }}
              {{ item.owner?.first_name ?? '' }}
            </span>
          </template>

          <template #body_payed_date="{ item }">
            {{ item.payed_date ? formatDate(item.payed_date) : '' }}
          </template>
        </resizable-table>

        <div class="movement-documents__count-element">
          <p class="movement-documents__count-element-text">Всего: {{ total }}</p>
        </div>
      </div>
    </div>
    <table-navbar
      :items="selected"
      :clear_selected="clearSelected"
      :all_items="items"
      items_name="invoice"
      @remove_items="removeHeads"
      @create_sale="createSale"
    />

    <pagination
      :total="total"
      table_name="invoice-docs"
      @change_pagination="handlerPagination"
    />
  </section>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import DocumentsHeader from '@/views/operational-processes/components/DocumentsHeader'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import Pagination from '@/views/shared/components/table/Pagination'
  import ResizableTable from '@/components/ResizableTable'
  import DocumentsTableFilter from '@/views/operational-processes/components/DocumentsTableFilter'
  import { formatMoney } from '@/utils/formatMoney'

  export default {
    name: 'Documents',
    components: {
      TableNavbar,
      Pagination,
      ResizableTable,
      DocumentsHeader,
      DocumentsTableFilter
    },
    apollo: {
      InvoiceHeads: {
        query: require('../../gql/getInvoiceHeads.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id,
            documentPagination: {
              skip: this.getPagination?.['invoice-docs']?.skip,
              take: this.getPagination?.['invoice-docs'].take,
              search: this.getPagination?.['invoice-docs'].search,
              period: {
                begin: this.data_range.begin ?? null,
                end: this.data_range.end ?? null
              }
            },
            filters: {
              pay_statuses: this.getPayStatuses,
              statuses: this.calcStatus,
              managers: this.getFilters?.owners.map((item) => item.id) || [],
              recipients: this.getFilters?.recipients.map((obj) => obj.id) || [],
              senders: this.getFilters?.senders.map((obj) => obj.id) || []
            }
          }
        },
        result({ data }) {
          this.selected = []
          this.isBusy = false
          this.items = data.InvoiceHeads.documents
          this.total = data.InvoiceHeads.total
          this.total_sum = {
            ret_sum: data.InvoiceHeads?.ret_sum || 0,
            pur_sum: data.InvoiceHeads?.pur_sum || 0
          }
        },
        error() {
          this.isBusy = false
        }
      }
    },
    data() {
      return {
        isBusy: true,
        selected_sum: null,
        total: 0,
        total_sum: {
          ret_sum: 0,
          pur_sum: 0
        },
        items: [],
        data_range: {
          begin: null,
          end: null
        },
        fields: [
          {
            key: 'id',
            label: '',
            class: 'id center',
            width: 40,
            checked: true
          },
          { key: 'date', label: 'Дата', width: 75, checked: true },
          {
            key: 'sum',
            label: 'Итого в закупочных ценах, ₽',
            width: 140,
            checked: true
          },
          {
            key: 'retail_sum',
            label: 'Итого в розниных ценах, ₽',
            width: 140,
            checked: true
          },
          {
            key: 'sender_entity',
            label: 'Юр лицо отправитель',
            width: 140,
            checked: true
          },
          {
            key: 'recipient_entity',
            label: 'Юр лицо получитель',
            width: 140,
            checked: true
          },
          { key: 'fixed', label: 'Статус', width: 120, checked: true },
          {
            key: 'payed',
            label: 'Оплата',
            width: 140,
            checked: true
          },
          { key: 'owner', label: 'Менеджер', width: 140, checked: true },
          { key: 'comment', label: 'Комментарий', width: 200, checked: true },
          {
            key: 'payed_date',
            label: 'Дата оплаты',
            width: 140,
            checked: true
          }
        ],
        selected: [],
        page_type: 'invoice',
        select_all: false
      }
    },
    watch: {
      select_all(newVal) {
        if (!newVal) return (this.selected = [])

        this.items.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
      },
      selected() {
        if (!this.selected.length) this.selected_sum = null
        else {
          let selected_docs = this.items.filter((obj) => this.selected.some((item) => item === obj.id))
          this.selected_sum = selected_docs.reduce(
            (acc, doc) => {
              return {
                sum: acc.sum + (+doc.sum ?? 0),
                retailSum: acc.retailSum + (+doc?.retail_sum ?? 0),
                nds: acc.nds + (+doc?.nds_sum ?? 0)
              }
            },
            { sum: 0, retailSum: 0, nds: 0 }
          )
        }
      }
    },
    methods: {
      ...mapActions({ setPagination: 'settings/set_pagination' }),
      updateDateRange(date) {
        this.data_range.begin = date[0]
        this.data_range.end = date[1]
      },
      clearSelected() {
        this.selected = []
        this.select_all = false
      },
      rowClicked({ id }) {
        this.$router.push({
          name: 'operation-process.overhead.invoice.document.edit',
          params: { id }
        })
      },
      handlerSearch(search) {
        this.setPagination({
          ...this.getPagination,
          ['invoice-docs']: {
            ...this.getPagination?.['invoice-docs'],
            search
          }
        })
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      formatPrice(val) {
        return formatMoney(val)
      },
      async removeHeads(ids) {
        await this.$apollo.mutate({
          mutation: require('../../gql/RemoveInvoiceDocument.graphql'),
          variables: {
            ids: ids
          }
        })
        this.$apollo.queries.InvoiceHeads.refresh()
        this.selected = []
      },
      async createSale(item) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/createSaleFromInvoice.graphql'),
          variables: {
            id: item
          }
        })
        if (data.createSaleFromInvoice?.id)
          this.$router.push({
            name: 'operation-process.overhead.cashless.document.edit',
            params: { id: data.createSaleFromInvoice.id }
          })
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) {
          this.setPagination({
            ...this.getPagination,
            ['invoice-docs']: {
              ...this.getPagination?.['invoice-docs'],
              skip: Number((current_page - 1) * take)
            }
          })
        }
        if (take) {
          this.setPagination({
            ...this.getPagination,
            ['invoice-docs']: {
              ...this.getPagination?.['invoice-docs'],
              take
            }
          })
        }
      },
      clearSearchStr() {
        this.setPagination({
          ...this.getPagination,
          ['invoice-docs']: {
            ...this.getPagination?.['invoice-docs'],
            search: ''
          }
        })
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getPagination: 'settings/getPagination',
        getFilters: 'invoice/getFilters'
      }),
      getPayStatuses() {
        const statuses = this.getFilters.pay_status.map((obj) => obj.id)
        if (statuses.length === 0 || statuses.length === 2) {
          return null
        }
        return statuses[0] === 'pay'
      },
      calcStatus() {
        const status = this.getFilters?.status.map((item) => item.id)
        if (status.length === 0 || status.length === 2) {
          return null
        }
        return status[0] === 'fixed'
      },
      title() {
        return this.$route.meta.title
      }
    }
  }
</script>

<style lang="scss" scoped>
  #movement-documents {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .b-table__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .b-table__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  :deep(.invoice-docs) th.cursor:not(.id):not(:nth-child(7)):not(:nth-child(8)) {
    position: relative;
    div {
      display: flex !important;
      float: left !important;
    }
  }
  :deep(.invoice-docs) .badge {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background: #efefef;
    color: #888888;

    &.success {
      background: #ebf9f5;
      color: #00cb91;
    }
  }

  .movement-documents__count-element {
    // height: 50px;
    display: flex;
    align-items: end;
    justify-content: end;
    margin-left: 20px;
  }
  .movement-documents__count-element-text {
    color: '#313131' !important;
    // padding-bottom: 0px !important;
    margin-bottom: 0px;
    padding: 16px;
  }
  :deep(.invoice-docs) td.text-nowrap {
    position: relative;

    .custom-checkbox {
      width: 100%;
      margin-right: 10px;
    }

    &.custom_checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .b-statuses {
      width: 2px !important;
      height: 32px !important;
      position: absolute;
      left: 0px;
      top: 50%;
      background: #bcbcbc;
      border-radius: 0px 1px 1px 0px;
      transform: translate(0%, -50%);

      &.success {
        background: #00cb91;
      }
    }
  }
  :deep(.invoice-docs) .table-docs {
    min-height: calc(100%) !important;
    padding-bottom: 0 !important;
    td.text-nowrap {
      position: relative;
      .b-status {
        width: 2px !important;
        height: 32px !important;
        position: absolute;
        left: 0px;
        top: 50%;
        background: #bcbcbc;
        border-radius: 0px 1px 1px 0px;
        transform: translate(0%, -50%);

        &.success {
          background: #00cb91;
        }
      }
    }
  }

  :deep(.invoice-docs) #table-docs {
    tr {
      cursor: pointer;
    }
  }

  :deep(.invoice-docs) th {
    padding: 10px 16px !important;
  }

  :deep(.invoice-docs) .invoice-list {
    max-height: 0 !important;
  }

  .bottom-descriprion {
    font-size: 12px;
    color: #888888;
  }
  @media (max-height: 700px) {
    :deep(.invoice-docs) .invoice-list {
      max-height: 100% !important;
    }
  }
</style>
